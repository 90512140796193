var tableColumns = [{
  title: "充值记录ID",
  dataIndex: "recordid",
  key: "recordid",
  width: 140
}, {
  title: "用户昵称",
  dataIndex: "username",
  key: "username",
  width: 120
}, {
  title: "充值金额",
  dataIndex: "amount",
  key: "amount",
  width: 120
}, {
  title: "Token类型",
  dataIndex: "tokentype",
  key: "tokentype",
  width: 120,
  scopedSlots: {
    customRender: "tokentype"
  }
}, {
  title: "网络",
  dataIndex: "networktype",
  key: "networktype",
  width: 100,
  scopedSlots: {
    customRender: "networktype"
  }
}, {
  title: "目标钱包",
  dataIndex: "targetwallet",
  key: "targetwallet",
  width: 140,
  scopedSlots: {
    customRender: "targetwallet"
  }
}, {
  title: "Tx hash",
  dataIndex: "txhash",
  key: "txhash",
  width: 180,
  scopedSlots: {
    customRender: "txhash"
  }
  // sorter: true,
  // sortOrder: "descend",
}, {
  title: "来源钱包地址",
  dataIndex: "originalwallet",
  key: "originalwallet",
  width: 180,
  scopedSlots: {
    customRender: "originalwallet"
  }
  // sorter: true,
  // sortOrder: "ascend",
}, {
  title: "充值到账时间",
  dataIndex: "arrivaltime",
  key: "arrivaltime",
  width: 120,
  scopedSlots: {
    customRender: "arrivaltime"
  }
}, {
  title: "充值发起时间",
  dataIndex: "starttime",
  key: "starttime",
  width: 110,
  scopedSlots: {
    customRender: "starttime"
  }
}, {
  title: "充值状态",
  dataIndex: "status",
  key: "status",
  width: 80,
  scopedSlots: {
    customRender: "status"
  }
}
// {
//   title: "操作",
//   key: "action",
//   dataIndex: "action",
//   fixed: "right",
//   width: 140,
//   scopedSlots: {
//     customRender: "action",
//   },
// },
];

var searchKeys = [{
  key: "date",
  label: "充值到账时间",
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  attrs: {
    valueFormat: "YYYY-MM-DD HH:mm:ss",
    allowClear: true
  }
},
// {
//   key: "userid",
//   label: "用户：",
//   placeholder: "请输入用户名称",
//   input: true,
//   attrs: { allowClear: true },
// },
{
  key: "originalwallet",
  label: "来源钱包地址：",
  placeholder: "请输入来源钱包地址",
  input: true,
  attrs: {
    allowClear: true
  }
},
// {
//   key: "targetwallettype",
//   label: "目标钱包",
//   placeholder: "请选择目标钱包",
//   select: true,
//   attrs: { allowClear: true },
// },
{
  key: "networktype",
  label: "网络",
  placeholder: "请选择网络类型",
  select: true,
  mode: "multiple",
  attrs: {
    allowClear: true
  }
}, {
  key: "txhash",
  label: "Tx Hash",
  placeholder: "请输入Tx Hash",
  input: true,
  attrs: {
    allowClear: true
  }
}, {
  key: "button",
  button: true
}];
export { tableColumns, searchKeys };